.sidebar-container {
    /* Parent container */
    display: grid;
    grid-template-columns: repeat(2, min-content);
    position: relative;
    height: 100dvh;
}

/* <---------------- Left side - the actual sidebar ----------------> */
.sidebar-dynamic-container {
    width: var(--sidebar-width);
}
.hidden-sidebar {
    width: 0 !important;
}
/* Sidebar contents */
.sidebar-sidebar-nav {
    background: var(--sidebar-color-bg);
}

/* <---------------- Right side - the app content ----------------> */
.sidebar-children-container {
    width: var(--page-width);
}
.hide-login {
    background: white;
}
.show-login {
    width: 100dvw;
    background: var(--login-color-bg);
}

/* <------------------------ Other details -----------------------> */
.navlink {
    display: flex;
    justify-content: space-evenly;
    height: 5dvh;
    padding: 1.3dvh 1dvw;
    color: white;
    font-weight: bold;
    border-top-width: 0;
    border-left-width: 0;
    border-radius: 0;
    border-bottom-width: 0;
    border-right-width: 0;
    box-shadow: none;
    margin: 0;
}
.navlink:hover {
    background-color: var(--sidebar-color-hover);
    color: black;
}
.app-sidebar > .active {
    background-color: var(--sidebar-color-active);
    border-top-width: 0;
    border-left-width: 0;
    border-radius: 0;
    border-bottom-width: 0;
    border-right-width: 0;
    box-shadow: none;
    margin: 0;
}
.sidebar-child-column {
    width: var(--sidebar-width);
    background-color: var(--sidebar-color-bg);
}


.Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100dvw;
}
.no-gutters {
    margin-right: 0 !important;
    margin-left: 0 !important;
  
    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
}

@media screen and (max-width: 1250px) {
}