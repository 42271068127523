.loader_spinner__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    z-index: 10000;
  }
  
  .loader_spinner__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .loader-spinner-msg {
    font-size: 1.3rem;
    color: red;
    z-index: 10000;
  }