/* @import url('https://fonts.googleapis.com/css2?fmaily=Poppins:wbht@300;400;500;600&display=swap'); */

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-primary: #4db5ff;
    --color-white: white;
    --transition: all 400ms ease;


    /* Sidebar - left side */
    --sidebar-width: 8dvw;
    --sidebar-height: 100dvh;

    --sidebar-color-bg: linear-gradient(0.25turn, #53BBDB, #75bae07d);
    --sidebar-color-active: #00AEE1;
    --sidebar-color-hover: #314ea5;

    /* Main body - right side */
    --page-width: calc(100dvw - var(--sidebar-width));

    --color-content-bubble: #cef0faaa;

    --dentigo-border-radius: 1dvh;

    --std-grid-columns-gap: 2dvw;

    --std-padding-sub: 3dvh;
    --std-padding-text: 3dvh;

    /* Login */
    --login-color-bg: linear-gradient(0.375turn, #7ECFE8, #006C8D);

    /* Questionable */
    --navbar-child-color-bg: #b9ccf5;

    /* Mobile */
    --small-mobile-font: 0.8rem;
    
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    color: black;
    line-height: 1.7;
    height: 100svh;
}

/* ============ GENERAL STYLES ============ */
.label {
    font-size: 1.5svh;
}
.strongLabel {
    font-size: 1.5svh;
    font-weight: bold;
}

.selector {
    width: 20vw;
    font-size: 1.5svh;
    margin: 0.5svh 1vw 1svh 1vw;
    padding: 0.5vw 0.2vw 0.5vw 0.4vw;
    border-radius: 0.5vw;
}
.inputor {
    width: 20vw;
    font-size: 1.5svh;
    margin: 0.5svh 1vw 1svh 1vw;
    padding: 0.5vw 0.2vw 0.5vw 0.4vw;
    border-radius: 0.5vw;
}
.tainputor {
    width: 22vw;
    background-color: rgb(244, 244, 244);
    margin: 0.5svh 1vw 1svh 1vw;
    padding: 0.5vw 0.2vw 0.5vw 0.4vw;
    border-radius: 0.5vw;
}
.createable {
    color: black;
    padding: 0.5vw 0 0.5vw 0;
    margin: 0.5svh 1vw 1svh 1vw;
    width: 20vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-y: scroll;
}
.createable_sm {
    color: black;
    padding: 0.5vw 0 0.5vw 0;
    margin: 0.5svh 1vw 1svh 1vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-y: scroll;
}


a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}
.smlbtn {
    font-family: Helvetica, Arial, sans-serif;
    transition: box-shadow 1s;
    background-color: white;
    font-size: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.5vh;
    margin-left: 0.5rem;
}
.smlbtnactive,
.chartbtnActive {
    transition: box-shadow 1s;
    border: 1px solid #29BAE7;
    color: white;
    box-shadow: inset 0 0 2em #29BAE7;
    font-weight: bold;
}
.btn {
    background-color: #697293;
    width: max-content;
    display: inline-block;
    color: white;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    /* border: 1px solid var(--color-primary); */
    transition: var(--transition);
    font-size: 1.8dvh;
}

.btn:hover {
    background: var(--color-white);
    color: #009bb6;
    border-color: transparent;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}
.form-check-label {
    font-size: 1.5svh;
}
@media screen and (max-width: 1400px) {
    .btn {
        font-size: 0.8rem !important;
    }
}
/* ============ MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1250px) {
    body {
        height: 100svh;
    }
    .smlbtn {
        font-size: 1.5svh;
        width: 3em;
        height: 2em;
    }
    .btn {
        font-size: var(--small-mobile-font) !important;
    }
}
@media screen and (max-width: 1024px){
    .container {
        width: var(--container-widge-md);
    }
}

/* ============ MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 600px){
    .container {
        width: var(--container-widge-sm);
    }
}