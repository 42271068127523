.dentist-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    font-weight: bold;
    color: white;
    height: 15dvh;
}
.dentist-sidebar {
    height: 65dvh;
}
.dentist-logout-button-container {
    display: flex;
    justify-content: center;
    height: 10dvh;
}

.sidebar-hr-container {
    display: flex;
    justify-content: center;
    height: 5dvh;
}
.sidebar-hr {
    color: white;
    opacity: 1;
    border-width: 2px;
    width: 4dvw;
}